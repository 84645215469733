<template>
  <div>
    <p class="text-dark">
      Time Remaining: <span class="text-danger">{{ formatTime }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "TimerComponent",
  data() {
    return {
      timeRemaining: 10 * 60 // 5 minutes in seconds
    };
  },
  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        clearInterval(this.timer);
        // Timer has reached 0, you can perform any actions here
        // console.log("Timer has finished");
        this.$emit("onExpired");
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer); // Clean up timer when component is destroyed
  }
};
</script>

<style>
/* Add any styles you need */
</style>
