<template>
  <div class="container-md overflow-auto" style="padding: 60px 14px;">
    <!-- header -->
    <!-- <b-row
        class="justify-content-center align-items-center"
        style="margin-bottom: 50px;"
      >
        <div :class="{ 'bg-secondary': isDark }" class="p-2">
          <img :src="siteLogo()" alt="Logo" class="brand-logo" />
        </div>
      </b-row> -->
    <!-- body -->
    <div class="row justify-content-center" v-if="isLoading">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </div>
    <b-row v-else>
      <b-col></b-col>
      <b-col cols="10">
        <template v-if="errorMessage">
          <div class="d-flex flex-column align-items-center">
            <b-icon
              icon="exclamation-triangle"
              variant="danger"
              style="width: 120px; height: 120px;"
            ></b-icon>
            <p class="h3">{{ errorMessage }}</p>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-column">
            <div class="row justify-content-center" v-if="paymentCreating">
              <b-icon
                icon="three-dots"
                animation="cylon"
                font-scale="4"
              ></b-icon>
            </div>
            <template v-else>
              <template v-if="!showPaymentResultSection">
                <b-row>
                  <div class="col-12">
                    <h6>
                      {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_INFO") }}
                    </h6>
                  </div>
                </b-row>
                <div class="row justify-content-center" v-if="orderCreating">
                  <b-icon
                    icon="three-dots"
                    animation="cylon"
                    font-scale="4"
                  ></b-icon>
                </div>
                <b-row class="mb-5" v-else>
                  <div class="col-12 col-md-4">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_IDENTITY")
                      }}:</label
                    >
                    <span class="ml-2">{{ getOrderNumber }}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT")
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ getPrice }} {{ selectedPaymentUnit }}</span
                    >
                  </div>
                  <div class="col-12 col-md-4">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t(
                          "PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_AMOUNT"
                        )
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ getFee }}% ({{ feeAmount }}
                      {{ selectedPaymentUnit }})</span
                    >
                  </div>
                  <div class="col-12 col-md-4">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_AMOUNT")
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ totalAmount }} {{ selectedPaymentUnit }}</span
                    >
                  </div>
                  <div class="col-12 col-md-4" v-if="totalQuantity">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.TOTAL_QUANTITY")
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ totalQuantity }}
                      <template v-if="selectedCryptoCode">
                        {{ selectedCryptoCode }}
                      </template>
                    </span>
                  </div>
                  <div class="col-12 col-md-4" v-if="nonUserOrderWalletAddress">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.WALLET")
                      }}:</label
                    >
                    <span class="ml-2">{{ nonUserOrderWalletAddress }} </span>
                  </div>
                  <div class="col-12" v-if="showCustomerInfo">
                    <div class="bg-secondary" style="height: 2px;"></div>
                  </div>
                  <div class="col-12 mt-3" v-if="showCustomerInfo">
                    <h6>
                      {{
                        $t(
                          "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.CUSTOMER_INFO"
                        )
                      }}
                    </h6>
                  </div>
                  <div
                    class="col-12 col-md-4"
                    v-if="createOrderForm.customerMail"
                  >
                    <label class="col-form-label font-weight-bold"
                      >{{
                        `${$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER")} ${$t(
                          "FORM.EMAIL"
                        )}`
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ createOrderForm.customerMail }}
                    </span>
                  </div>
                  <div
                    class="col-12 col-md-4"
                    v-if="createOrderForm.customerName"
                  >
                    <label class="col-form-label font-weight-bold"
                      >{{
                        `${$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER")} ${$t(
                          "FORM.FIRST_NAME"
                        )}`
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ createOrderForm.customerName }}
                    </span>
                  </div>
                  <div
                    class="col-12 col-md-4"
                    v-if="createOrderForm.customerPhone"
                  >
                    <label class="col-form-label font-weight-bold"
                      >{{
                        `${$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER")} ${$t(
                          "FORM.PHONE"
                        )}`
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ createOrderForm.customerPhone }}
                    </span>
                  </div>
                </b-row>
                <b-row class="mb-4">
                  <currency-selector
                    v-if="!cryptosLoading && !getCryptoFromQueryParam"
                    v-on:currency-selected="onCryptoSelected"
                    :currencies="cryptos"
                    :selectedCurrency="selectedCrypto"
                    containerClass="col-12"
                  ></currency-selector>
                </b-row>
                <b-row>
                  <div class="col-12">
                    <b-button
                      variant="success"
                      block
                      class="d-inline-flex justify-content-center align-items-center"
                      :disabled="!selectedCrypto || !selectedPaymentUnit"
                      @click="onClickNonMcmUser"
                    >
                      <div
                        class="mr-2 font-weight-bold"
                        style="font-family: 'Poppins', sans-serif;"
                      >
                        Pay with
                      </div>
                      <img
                        :src="require('@/assets/images/mpay-btn.png')"
                        alt="mpay"
                        style="max-height: 14px; width: auto;"
                      />
                    </b-button>
                  </div>
                </b-row>
              </template>

              <!-- SHOW Payment result status -->
              <template v-else>
                <div class="d-flex flex-column align-items-center">
                  <b-alert
                    :variant="paymentReceivedResult.variant"
                    class="col-9 col-md-12 text-center"
                    show
                  >
                    <p class="h1 mb-2">
                      <b-icon
                        :icon="
                          paymentReceiveSuccess
                            ? 'check-circle-fill'
                            : 'exclamation-circle-fill'
                        "
                      ></b-icon>
                    </p>
                    <h5 class="alert-heading">
                      {{ paymentReceivedResult.message }}
                      <div
                        class="row justify-content-center"
                        v-if="
                          !isMcmUser &&
                            paymentReceiveIsPending &&
                            nonUserOrderWalletAddress
                        "
                      >
                        <b-icon
                          icon="three-dots"
                          animation="cylon"
                          font-scale="4"
                        ></b-icon>
                      </div>
                      <div
                        v-if="
                          !isMcmUser &&
                            paymentReceiveIsPending &&
                            nonUserOrderWalletAddress
                        "
                        class="flex flex-column"
                      >
                        <p class="font-weight-bold text-dark">
                          The QR code will be invalid after 10 minutes.
                        </p>
                        <TimerComponent @onExpired="onTimerExpired" />
                      </div>
                    </h5>
                  </b-alert>
                  <div
                    class="d-flex flex-column align-items-center col-9 col-md-12 text-center justify-content-center"
                    v-if="
                      (paymentReceiveSuccess || paymentReceiveIsPending) &&
                        !isMcmUser &&
                        nonUserOrderWalletAddress
                    "
                  >
                    <div class="non-mcm-user-qrcode-wrapper mb-3">
                      <qrcode-vue
                        :value="nonUserOrderWalletAddress"
                        :size="175"
                        level="H"
                      />
                    </div>
                    <div class="qr-warning" v-if="paymentReceiveIsPending">
                      <p
                        v-html="
                          `<i class='fa fa-exclamation-triangle text-danger' style='font-size: 12px; margin-right: 3px;'></i>${qrWarningText}`
                        "
                      ></p>
                    </div>
                    <div class="my-4 d-flex non-user-wallet-address">
                      <h6
                        class="text-truncate text-break"
                        @click="onCopyText(nonUserOrderWalletAddress)"
                      >
                        {{ nonUserOrderWalletAddress }}
                      </h6>
                      <div
                        @click="onCopyText(nonUserOrderWalletAddress)"
                        class="ml-2"
                      >
                        <i class="fa fa-copy text-info fa-2x"></i>
                      </div>
                    </div>
                    <h4
                      class="font-weight-light mb-5"
                      v-if="nonUserOrderWalletNetworkName"
                    >
                      {{ $t("COMMON.network") }}:
                      <strong>{{ nonUserOrderWalletNetworkName }}</strong>
                    </h4>
                    <h4
                      class="font-weight-light"
                      v-if="getNonMcmTransactionAmount"
                    >
                      {{
                        $t(
                          "PAGES.SALES_MANAGEMENT.QR_SALE.ORDER_CRYPTO_AMOUNT_IS"
                        )
                      }}:
                      <strong>{{ getNonMcmTransactionAmount }}</strong>
                    </h4>
                  </div>
                  <div
                    v-if="notEnoughPayment && !isMcmUser"
                    class="d-flex flex-column align-items-center col-9 col-md-12 text-center justify-content-center pt-4"
                  >
                    <h4
                      class="font-weight-light mb-5"
                      v-if="getNonMcmDiffPrice"
                    >
                      {{ $t("missing_transaction_amount") }}:
                      <strong>{{ getNonMcmDiffPrice }}</strong>
                    </h4>
                    <h4
                      class="font-weight-light mb-5"
                      v-if="getNonMcmDiffAmount"
                    >
                      {{ $t("missing_crypto_amount") }}:
                      <strong>{{ getNonMcmDiffAmount }}</strong>
                    </h4>
                    <div class="d-flex align-items-center h4 mt-5">
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        style="margin-right: 3px;"
                      ></i>
                      {{ $t("pay_sdk_missing_payment_warning") }}
                    </div>
                    <div
                      class="d-flex align-items-center h4 mt-1"
                      v-if="
                        getInsufficientPaymentStateExtraInfoTextFromQueryParam
                      "
                    >
                      {{
                        getInsufficientPaymentStateExtraInfoTextFromQueryParam
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="row justify-content-center"
                  v-if="paymentReceiveSuccess"
                >
                  <b-button
                    pill
                    variant="primary"
                    class="mt-5"
                    style="min-width: 50%;"
                    @click.prevent="onSuccessRedirect"
                    size="lg"
                  >
                    <b-icon
                      font-scale="1.5"
                      icon="check"
                      aria-hidden="true"
                    ></b-icon>
                    {{ $t("FORM.OK") }}
                  </b-button>
                </div>
                <div
                  class="row justify-content-center"
                  v-if="notEnoughPayment && !isMcmUser"
                >
                  <b-button
                    pill
                    variant="info"
                    class="mt-5"
                    style="min-width: 40%;"
                    @click.prevent="onInsufficientOK"
                    size="lg"
                  >
                    {{ $t("FORM.OK") }}
                  </b-button>
                </div>
              </template>
            </template>
          </div>
        </template>
      </b-col>
      <b-col></b-col>
    </b-row>
    <!-- <div
        class="flex text-center px-5 justify-content-center"
        style="margin-top: 50px;"
      >
        <span class="text-center font-weight-bold">
          Miracle Pay is a cryptocurrency payment platform owned by Miracle Cash &
          More.
          <a
            class="text-primary"
            href="https://trade.miraclecash.com/register"
            target="_blank"
            >Click here to create your Miracle Cash & More account.</a
          >
        </span>
      </div> -->
  </div>
</template>
<script>
import mpayApi from "../../services/api";
import mcmApi from "../../services/apiMcm";
import CurrencySelector from "../../components/currency/CurrencySelector.vue";
// import QrCodeReader from "../../components/QrCode/QrCodeReader.vue";
import QrcodeVue from "qrcode.vue";
import i18n from "../../plugins/vue-i18n";
import TimerComponent from "../../components/common/TimerComponent";

const validateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export default {
  name: "PaySdk",
  components: {
    CurrencySelector,
    // QrCodeReader,
    QrcodeVue,
    TimerComponent
  },
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      parentId: null,
      orderCreating: false,
      createdOrder: null,
      paymentUnitsLoading: false,
      paymentUnits: null,
      selectedPaymentUnit: null,
      cryptosLoading: false,
      cryptos: null,
      selectedCrypto: null,
      convertedCryptoAmount: null,
      nonUserOrderWalletAddress: null,
      nonUserOrderWalletNetworkName: null,
      isMcmUser: true,
      paymentCreating: false,
      showPaymentResultSection: false,
      paymentReceivedResult: {
        variant: "success",
        message: this.$t(
          "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_SUCCESS"
        )
      },
      listenNexusOrderInterval: null,
      notEnoughPayment: false
    };
  },
  computed: {
    accessToken() {
      return this.$route.query.token;
    },
    orderPrice() {
      return this.$route.query.order_price;
    },
    commissionPayerFromQueryParam() {
      return this.$route.query.commission_payer;
    },
    commissionPayer() {
      return this.commissionPayerFromQueryParam || "customer";
    },
    redirectUrl() {
      return this.$route.query.rt_url;
    },
    getCryptoFromQueryParam() {
      return this.$route.query?.crypto || null;
    },
    getCustomerMail() {
      return this.$route.query?.customer_email || "";
    },
    getCustomerName() {
      return this.$route.query?.customer_name || "";
    },
    getCustomerPhone() {
      return this.$route.query?.customer_phone || "";
    },
    theme() {
      const _themeFromQueryParam = this.$route.query.theme;
      if (
        !_themeFromQueryParam ||
        !["light", "dark"].includes(_themeFromQueryParam)
      ) {
        return "light";
      }
      return _themeFromQueryParam;
    },
    getLocale() {
      const _locQueryParam = this.$route.query.locale;
      if (!_locQueryParam || !["en", "tr"].includes(_locQueryParam)) {
        return "en";
      }
      return _locQueryParam;
    },
    isDark() {
      return this.theme === "dark";
    },
    defaultAxiosConf() {
      return {
        headers: {
          Authorization: `Bearer ${this.accessToken}`
        }
      };
    },
    createOrderForm() {
      return {
        customerMail: this.getCustomerMail,
        customerName: this.getCustomerName,
        customerPhone: this.getCustomerPhone,
        devicesId: 2, // 1:pos, 2:virtual pos, 3:web, 4: woocommerce
        feeIncluded: this.commissionPayer === "branch" ? true : false, // if true the BRANCH will pay the commission else the CUSTOMER
        price: `${this.orderPrice}`
      };
    },
    getOrderId() {
      return this.createdOrder?.id
        ? this.createdOrder.id
        : this.createdOrder?._id;
    },
    getOrderNumber() {
      return this.createdOrder?.orderNumber || null;
    },
    getOrderAmount() {
      return this.createdOrder?.amount || null;
    },
    getPrice() {
      return this.createdOrder?.price || null;
    },
    getFee() {
      return this.createdOrder?.fee || null;
    },
    feeAmount() {
      return this.createdOrder?.feeAmount || null;
    },
    totalAmount() {
      return this.createdOrder?.orderTotal || null;
    },
    totalQuantity() {
      return this.convertedCryptoAmount
        ? this.convertedCryptoAmount
        : this.getOrderAmount;
    },
    selectedCryptoCode() {
      return this.selectedCrypto?.wallet?.currencyCode || null;
    },
    selectedCryptoId() {
      return this.selectedCrypto?.id || null;
    },
    paymentReceiveSuccess() {
      return this.paymentReceivedResult.variant === "success";
    },
    getNonMcmTransactionAmount() {
      return `${this.createdOrder?.amount || ""} ${this.selectedCryptoCode}`;
    },
    showCustomerInfo() {
      return (
        this.createOrderForm.customerName ||
        this.createOrderForm.customerMail ||
        this.createOrderForm.customerPhone
      );
    },
    paymentReceiveIsPending() {
      return this.paymentReceivedResult?.variant === "warning";
    },
    qrWarningText() {
      return this.$t("paySdkQrWarning", {
        value: this.selectedCryptoCode?.toUpperCase()
      });
    },
    getAutoSubmitPayBtn() {
      return this.$route.query?.auto_submit_pay_btn
        ? `${this.$route.query.auto_submit_pay_btn}` === "true"
        : false;
    },
    getNonMcmDiffPrice() {
      return (
        this.createdOrder?.diffPrice &&
        `${this.createdOrder.diffPrice} ${this.selectedPaymentUnit}`
      );
    },
    getNonMcmDiffAmount() {
      return (
        this.createdOrder?.diffAmount &&
        `${
          this.createdOrder.diffAmount
        } ${this.selectedCryptoCode?.toUpperCase()}`
      );
    },
    theOrder() {
      return {
        ...this.createdOrder,
        nonUserOrderWalletAddress: this.nonUserOrderWalletAddress,
        nonUserOrderWalletNetworkName: this.nonUserOrderWalletNetworkName
      };
    },
    getInsufficientPaymentStateExtraInfoTextFromQueryParam() {
      return this.$route.query?.insufficient_payment_state_extra_info_text;
    }
  },
  async mounted() {
    i18n.locale = this.getLocale;
    if (this.isDark) {
      document.body.classList.add("bg-dark", "text-white");
    }
    this.errorMessage = null;
    // validation
    if (!this.accessToken) {
      this.errorMessage = "Authentication Error!";
      return;
    }
    if (!this.orderPrice) {
      this.errorMessage = "Order Price must be present.";
      return;
    }
    if (!/^\d+(\.\d{1,2})?$/.test(this.orderPrice)) {
      this.errorMessage =
        "Order Price must be numeric and accepts max 2 digit.";
      return;
    }
    if (
      this.commissionPayerFromQueryParam &&
      !["branch", "customer"].includes(this.commissionPayerFromQueryParam)
    ) {
      this.errorMessage = "Commission Payer is invalid.";
      return;
    }
    if (this.getCustomerMail && !validateEmail(this.getCustomerMail)) {
      this.errorMessage = "Customer Email is invalid.";
      return;
    }
    // validation

    this.selectedPaymentUnit = "EUR";

    await this.getMe();
    await this.createdOrderAction();
    await this.getPaymentUnits();

    // trigger auto submit for pay btn based on query parameter and if conditions meet
    if (
      this.selectedCrypto &&
      this.selectedPaymentUnit &&
      this.getAutoSubmitPayBtn
    ) {
      await this.onClickNonMcmUser();
    }

    // Receive QR code result from parent window
    window.addEventListener("message", event => {
      const data = event.data;
      if (data && data.type === "qr_code_result") {
        this.handleQRCodeResult(data.data);
      }
    });
  },
  methods: {
    onSuccess() {
      window.parent.postMessage(
        {
          event: "onSuccess",
          data: this.theOrder
        },
        "*"
      );
    },
    onSuccessRedirect() {
      if (this.redirectUrl) {
        window.location.href = this.redirectUrl;
      } else {
        window.parent.postMessage(
          {
            event: "onSuccessRedirect",
            data: this.theOrder
          },
          "*"
        );
      }
    },
    onInsufficientPayment() {
      window.parent.postMessage(
        {
          event: "onInsufficientPayment",
          data: this.theOrder
        },
        "*"
      );
    },
    onError() {
      window.parent.postMessage(
        {
          event: "onError",
          data: this.theOrder
        },
        "*"
      );
    },
    onInsufficientOK() {
      window.parent.postMessage(
        {
          event: "onClickInsufficientOK"
        },
        "*"
      );
    },
    async getMe() {
      this.isLoading = true;
      try {
        const res = await mpayApi.get("/me", this.defaultAxiosConf);
        this.isLoading = false;
        const role = res.data?.userRole?.roleRank;
        const _parentId = res.data?.parent?.id;
        const isKycVerified = res.data?.documentState || false;
        if (!role || role !== "ROLE_BRANCH" || !_parentId || !isKycVerified) {
          this.errorMessage = "Authentication Error";
          throw "Authentication Error";
        }
        this.parentId = _parentId;

        const findCryptoFromQueryParam = await this.getCompanyWallets();
        if (this.getCryptoFromQueryParam) {
          if (!findCryptoFromQueryParam) {
            this.errorMessage = "Invalid crypto selected.";
            return;
          } else {
            this.selectedCrypto = findCryptoFromQueryParam;
          }
        }

        return res.data;
      } catch (error) {
        this.isLoading = false;
        this.errorMessage = "Authentication Error";
        throw error;
      }
    },
    async createdOrderAction() {
      this.orderCreating = true;
      try {
        const res = await mpayApi.post(
          "company-orders",
          this.createOrderForm,
          this.defaultAxiosConf
        );
        this.orderCreating = false;
        this.createdOrder = res.data;
        if (
          this.selectedPaymentUnit &&
          !!this.selectedCrypto &&
          !!this.totalAmount
        ) {
          this.convertCurrencyToCrypto({
            crypto: this.selectedCrypto.wallet.currencyCode,
            currency: this.selectedPaymentUnit
          });
        }
        return res;
      } catch (error) {
        this.orderCreating = false;
        this.errorMessage = "Order can not be created.";
        throw error;
      }
    },
    async getCompanyWallets() {
      this.cryptosLoading = true;
      try {
        const res = await mpayApi.get(
          `companies/${this.parentId}/company-wallets`,
          this.defaultAxiosConf
        );
        this.cryptosLoading = false;
        this.cryptos = res?.data || [];
        const findCryptoFromQueryParam = this.getCryptoFromQueryParam
          ? res.data?.find(
              val =>
                val.wallet.currencyCode.toUpperCase() ===
                this.getCryptoFromQueryParam.toUpperCase()
            )
          : null;
        return findCryptoFromQueryParam;
      } catch (error) {
        this.cryptosLoading = false;
        throw error;
      }
    },
    async getPaymentUnits() {
      this.paymentUnitsLoading = true;
      try {
        const resp = await mpayApi.get("payment-units", this.defaultAxiosConf);
        this.paymentUnits =
          resp?.data?.map(val => ({
            ...val,
            label: val.name.toUpperCase()
          })) || [];
        this.paymentUnitsLoading = false;
      } catch (error) {
        this.paymentUnitsLoading = false;
        throw error;
      }
    },
    onCryptoSelected(crypto) {
      this.selectedCrypto = crypto;
      if (!!crypto?.wallet?.currencyCode && this.selectedPaymentUnit) {
        this.convertCurrencyToCrypto({
          crypto: crypto.wallet.currencyCode,
          currency: this.selectedPaymentUnit
        });
      }
    },
    onChangePaymentUnit() {
      if (this.selectedPaymentUnit && !!this.selectedCrypto) {
        this.convertCurrencyToCrypto({
          crypto: this.selectedCrypto.wallet.currencyCode,
          currency: this.selectedPaymentUnit
        });
      }
    },
    async convertCurrencyToCrypto({ crypto, currency }) {
      try {
        const res = await mcmApi.post("miracle-pay/convert", {
          crypto,
          currency,
          amount: this.totalAmount
        });
        this.convertedCryptoAmount = res.data?.crypto_amount || "";
      } catch (error) {
        // console.log(error.response?.data?.message || "");
      }
    },
    requestCameraPermission() {
      window.parent.postMessage({ type: "open_qr_code_reader" }, "*");
    },
    handleQRCodeResult(result) {
      this.OnQRCodeScanned(result);
    },
    OnQRCodeScanned(qrCode) {
      this.isMcmUser = true;
      if (qrCode) {
        this.showPaymentResultSection = true;
        this.paymentCreating = true;
        this.paymentReceivedResult = {
          variant: "success",
          message: ""
        };
        mpayApi
          .post(
            `/company-orders/${this.getOrderId}/transactions/qr-sale`,
            {
              orderId: this.getOrderId,
              selectedCurrencyId: this.selectedCryptoId,
              selectedCurrencyCode: this.selectedCryptoCode,
              hash: qrCode,
              selectedPaymentName: this.selectedPaymentUnit
            },
            this.defaultAxiosConf
          )
          .then(() => {
            this.onSuccess();
            this.paymentCreating = false;
            this.paymentReceivedResult.message = this.$t(
              "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_SUCCESS"
            );
          })
          .catch(() => {
            this.paymentCreating = false;
            this.paymentReceivedResult.variant = "danger";
            this.paymentReceivedResult.message = this.$t(
              "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_ERROR"
            );
          });
      }
    },
    async onClickNonMcmUser() {
      this.showPaymentResultSection = true;
      this.isMcmUser = false;
      this.paymentCreating = true;
      this.paymentReceivedResult = {
        variant: "warning",
        message: ""
      };
      try {
        const res = await mpayApi.get(
          `/nexus/create_payment/${this.selectedCryptoCode}/${this.getOrderId}/${this.selectedPaymentUnit}`,
          this.defaultAxiosConf
        );
        await this.refreshOrderNexusAmount();
        this.paymentCreating = false;
        if (!res.data?.address) {
          this.paymentReceivedResult.variant = "danger";
          this.paymentReceivedResult.message = this.$t(
            "PAGES.SALES_MANAGEMENT.QR_SALE.active_wallet_address_not_found_please_try_again_later"
          );
          throw "Err: wallet address not found";
        }
        this.startIntervalForNexusOrder();
        this.paymentReceivedResult.message = this.$t(
          "PAGES.SALES_MANAGEMENT.QR_SALE.nexusCreatePaymentWarning"
        );
        this.nonUserOrderWalletAddress = res.data.address;
        this.nonUserOrderWalletNetworkName =
          res.data?.walletNetwork?.name || null;
        window.parent.postMessage(
          {
            event: "onGetNexusPaymentAddress",
            data: res.data.address
          },
          "*"
        );
      } catch (error) {
        this.paymentCreating = false;
        this.paymentReceivedResult.variant = "danger";
        this.paymentReceivedResult.message = this.$t(
          "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_ERROR"
        );
        throw error;
      }
    },
    startIntervalForNexusOrder() {
      this.listenNexusOrderInterval = setInterval(() => {
        this.refreshOrderNexusAmount();
      }, 5000);
    },
    stopIntervalForNexusOrder() {
      if (this.listenNexusOrderInterval) {
        clearInterval(this.listenNexusOrderInterval);
      }
    },
    async refreshOrderNexusAmount() {
      this.paymentReceivedResult = {
        variant: "warning",
        message: this.$t(
          "PAGES.SALES_MANAGEMENT.QR_SALE.nexusCreatePaymentWarning"
        )
      };
      try {
        const res = await mpayApi.get(
          `company-orders/${this.getOrderId}`,
          this.defaultAxiosConf
        );
        const { status } = res.data;
        this.createdOrder = { ...this.createdOrder, ...res.data };
        const isCompleted = `${status}` === "1";
        const insufficientPayment = `${status}` === "5";
        const shouldShowResult = isCompleted || insufficientPayment;
        if (shouldShowResult) {
          this.stopIntervalForNexusOrder();
          if (this.nonUserOrderWalletAddress) {
            this.deleteNonMcmWalletAddress(this.nonUserOrderWalletAddress);
          }
          if (isCompleted) {
            this.onSuccess();
            this.paymentReceivedResult.variant = "success";
            this.paymentReceivedResult.message = this.$t(
              "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_SUCCESS"
            );
          } else {
            throw "missing_order_payment";
          }
        }
      } catch (error) {
        const isMissingPayment = error === "missing_order_payment";
        this.notEnoughPayment = isMissingPayment;
        isMissingPayment ? this.onInsufficientPayment() : this.onError();
        const messageKey = isMissingPayment
          ? "PAGES.SALES_MANAGEMENT.QR_SALE.MISSING_ORDER_PAYMENT"
          : "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_ERROR";
        this.paymentReceivedResult.variant = isMissingPayment
          ? "info"
          : "danger";
        this.paymentReceivedResult.message = this.$t(messageKey);
        throw error;
      }
    },
    async deleteNonMcmWalletAddress(address) {
      await mpayApi.post(
        "delete-wallet-address",
        { address },
        this.defaultAxiosConf
      );
    },
    onTimerExpired() {
      this.stopIntervalForNexusOrder();
      if (this.nonUserOrderWalletAddress) {
        this.deleteNonMcmWalletAddress(this.nonUserOrderWalletAddress);
      }
      this.paymentReceivedResult.variant = "danger";
      this.paymentReceivedResult.message = "Your QR code has been expired.";
    },
    onCopyText(text) {
      window.parent.postMessage(
        {
          event: "onCopyWalletAddress",
          data: text || ""
        },
        "*"
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.brand-logo {
  max-height: 35px;
  width: auto;
}
.non-mcm-user-qrcode-wrapper {
  display: flex;
  justify-content: center;
  padding: 25px;
  border-radius: 5px;
  background: #fff;
}
.non-user-wallet-address {
  h6 {
    text-wrap: wrap;
  }
  div:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.qr-warning {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #52525b;
  align-items: center;
  justify-content: center;
}
</style>
